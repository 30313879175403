<template>
    <iframe class="h-100 w-100" :src="url" ref="viewerRef" />
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

export interface Props {
    fileName?: string,
    primKey?: string,
    viewName?: string,
    readOnly?: boolean,
    viewerOnly?: boolean
    openBlob?:string,
    pdffileRef?:string
};


const encodeObject = (pValue: any) => {
    const jsonString = JSON.stringify({
        fileName: props.fileName,
        primKey: props.primKey,
        viewName: props.viewName,
        readOnly: props.readOnly,
        viewerOnly: props.viewerOnly,
        openBlob: props.openBlob,
        fileRef: props.pdffileRef
        // time_stamp: Date.now() //for performance enhancement, using pdffileref instead
    });
    const encodedString = btoa(encodeURIComponent(jsonString));
    return encodedString;
}
const props = defineProps<Props>();
watch(() => props.primKey, (newValue, oldValue) => {
    if(newValue && !url.value){
        url.value = `/nt/foxit9-viewer?params=${encodeObject(props)}${props.pdffileRef ? '' : '&TimeStamp='+Date.now()}`
    }
    else{
        loadDocument();
    }
})

const url = ref();
const viewerRef = ref(null);
if(props.primKey || props.openBlob){
    url.value = `/nt/foxit9-viewer?params=${encodeObject(props)}${props.pdffileRef ? '' : '&TimeStamp='+Date.now()}`;
}
function loadDocument(){
    viewerRef.value.contentWindow.postMessage(`{"action": "LOAD_NEW_PDF", "props": "${encodeObject(props)}"}`);
}
</script>